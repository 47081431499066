import { Subscriber } from './../../common/classes/Subscriber';
import { DevSmsService } from './dev-sms.service';
import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { IDevMessage, IDevMessageResponse } from './dev-sms.model';

@Component({
  selector: 'app-dev-sms',
  templateUrl: './dev-sms.component.html',
  styleUrls: ['./dev-sms.component.less']
})
export class DevSmsComponent extends Subscriber implements OnInit {
  offset = 0;
  limit = 20;
  loading = true;
  messages: IDevMessage[] = [];
  pageIndex = 0;
  count = 0;

  constructor(private devSmsService: DevSmsService) {
    super()
  }

  ngOnInit(): void {
    this.loadMessages();
  }

  loadMessages() {
    this.loading = true;
    this.devSmsService.getDevMessages(this.offset, this.limit)
    .pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: IDevMessageResponse) => {
      this.loading = false;
      this.messages = response.messages;
      this.count = response.count;
    },
    err => {
      this.messages = [];
      this.loading = false;
      console.log(err.error?.message || err.message);
    })
  }

  selectPage(page) {
    console.log(page)
    if(this.pageIndex === page.pageIndex && this.limit === page.pageSize) {
      return;
    }
    
    this.pageIndex = page.pageIndex;
    this.limit = page.pageSize;
    this.offset = this.pageIndex * this.limit;
    this.loadMessages()
  }
}
