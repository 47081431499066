<app-loader *ngIf="loading"></app-loader>
<div *ngIf="!loading" class="messages-container">
    <div class="messages">
        <div class="message header">
            <div class="date">date</div>
            <div class="sender">sender</div>
            <div class="recepient">recepient</div>
            <div class="message">message</div>
        </div>
        <div class="message" *ngFor="let message of messages">
            <div class="date">{{message.date}}</div>
            <div class="sender">{{message.sender}}</div>
            <div class="recepient">{{message.recepient}}</div>
            <div class="message">{{message.message}}</div>
        </div>
    </div>
</div>

<div class="pages" *ngIf="count">
    <mat-paginator [length]="count"
                    [pageSize]="limit"
                    [pageSizeOptions]="[5, 10, 20, 50]"
                    color="accent"
                    (page)="selectPage($event)">
    </mat-paginator>
</div>
